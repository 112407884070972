<template>
	<div id="login">
		<login-frame></login-frame>
	</div>
</template>

<script> 
import loginFrame from '@/components/content/login/loginFrame.vue'
// import {setSession} from '@/utils/utils.js' 
export default {
	name:'Login',
	data(){
		return {
		}
	},
	created(){
		//document.title = "登录"
	},
	components:{
		loginFrame
	},
	methods:{
		
	}
}
</script>

<style lang="scss">
	@media(min-width: 1201px){
		@import '@/pages/login/Login.scss';
	}
	@media(max-width: 1200px){
		@import '@/pages/login/Login_phone.scss';
	}

</style>
