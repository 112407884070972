<!--
	Description: 登录框
-->
<template>
  <div id="loginframe" v-show="true">
    <div class="login">
      <div class="title">
        <img src="~/assets/images/nav/logo_zqt_white.png" />
        <!-- <img src="~/assets/images/login/zqt_logo.png" alt="资源获取失败" />
        <span>证企通</span> -->
      </div>
      <div class="main">
        <div class="left">
          <el-tabs v-model="activeName" @tab-click="selectTab">
            <el-tab-pane label="密码登录" name="first">
              <el-input
                type="tel"
                placeholder="请输入手机号码"
                v-model="userPhone"
                maxlength="11"
                onKeypress="return(/[\d\.]/.test(String.fromCharCode(event.keyCode)))"
                @blur="enterclick()"
                @keyup.enter.native="enterclick($event)"
              >
              </el-input>
              <el-input
                type="text"
                placeholder="请输入密码"
                v-model="userPassword"
                ref="password"
                show-password
                @keyup.enter.native="loginByUnamePwd()"
              ></el-input>
            </el-tab-pane>
            <el-tab-pane label="快捷登录" name="second">
              <el-input
                class="getCodeInput"
                type="tel"
                placeholder="请输入手机号码"
                v-model="userPhone"
                maxlength="11"
                onKeypress="return(/[\d\.]/.test(String.fromCharCode(event.keyCode)))"
              >
              </el-input>
              <div v-if="!iscode" @click="getcode" class="getCode">
                获取验证码
              </div>
              <div
                v-if="iscode"
                class="getCodes"
                @click="secode <= 0 ? getcode : ''"
              >
                {{ secode }}秒后重新获取
              </div>
              <el-input
                type="text"
                placeholder="请输入验证码"
                v-model="userCode"
                show-password
              ></el-input>
            </el-tab-pane>
          </el-tabs>
          <!-- 账号或密码错误 -->
          <div class="tipinfo" :style="message.color">
            {{ message.content }}
          </div>
          <div class="loginontime">
            <el-button
            class="nlogin"
            type="primary"
            :loading="control_loginloading"
            @click="loginByUnamePwd"
              >立即登录</el-button
            >
          </div>
          <div class="otheroperation">
            <span class="op_left">
              <span @click="regist">免费注册</span>
              <span @click="forgetpwd">忘记密码?</span>
            </span>
            <span v-show="isShow_isRemember">
              <el-checkbox v-model="isRemember"></el-checkbox>
              记住密码</span>
          </div>
        </div>
        <div class="right">
          <div class="logo">
            <!-- <img src="~/assets/images/nav/logo.png" alt="找不到资源" /> -->
          </div>
          <div class="qrcode">
            <!-- <img src="~/assets/images/login/qr_code.png" alt="找不到资源" /> -->
            <img src="~/assets/images/home/code.jpg" alt="找不到资源" />
          </div>
          <!-- <div class="scanlogin"><span>扫码登录</span></div> -->
        </div>
      </div>
      <div class="bottom">
        <el-checkbox v-model="certificationAgreement" ></el-checkbox>
        <span class="agreement_content">
          我已经详细阅读并同意资质<span class="loginappointment"  @click="toAgreement">《用户协议》</span>、<span class="loginappointment" @click="toSecretAgreement">《隐私保护指引》</span></span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { setSession, getSession } from "@/utils/utils.js";
import { setCookie, getCookie, checkCookie, clearCookie} from "@/utils/cookie.js";
export default {
  name: "loginFrame",
  data() {
    return {
      iscode: false,
      secode: 60,
      codetime: null,
      activeName: "first", //默认账号密码登录
      userPhone: "", //用户手机号=用户账号
      userPassword: "", //用户密码
      userCode: "", //信息验证码
      message: {
        content: "",
        color: "",
      }, //错误提示
      control_loginloading: false, //控制登录验证时间
      certificationAgreement: false, //协议勾选
      isShow_isRemember:true,//是否展示记住密码按钮
      isRemember: false,  // 是否记住密码
    };
  },
  props: {
    showLogin: Boolean,
  },
  created(){
     
  },
  mounted() {
    let isCookie = checkCookie('szjz_userpwd')
     if(isCookie){
        this.userPassword = getCookie('szjz_userpwd')
        this.isRemember = true
        this.certificationAgreement = true 
      }
    let userphone = localStorage.getItem("userinfo_phone");
    if (userphone != null) {
      this.userPhone = userphone;
    }
  },
  methods: {
    //点击跳转用户协议
    toAgreement(){
      let routeData = this.$router.resolve({ name: 'agreement' });
      window.open(routeData.href, '_blank');
    },
    //点击跳转隐私协议
    toSecretAgreement(){
      let routeData1 = this.$router.resolve({ name: 'agreement1'})
      window.open(routeData1.href,'_blank')
    }, 
    //获取验证码
    getcode() {     
      this.iscode = true;
      this.secode = 60;
      this.codetime = setInterval(() => {
        this.secode--;
        if (this.secode <= 0) {
          this.iscode = false;
          clearInterval(this.codetime);
        }
      }, 1000);
    },
    //点击跳转修改密码界面
    forgetpwd() {
      this.$router.push("/forget_password");
    },
    //点击跳转注册界面
    regist() {
      this.$router.push("/regist");
    },
    //回车触发
    enterclick(event) {
      if (event) {
        event.target.blur();
        this.$refs.password.focus();
      }
    },
    //选择登录方式并登录
    loginByUnamePwd(){
      this.control_loginloading = true;
      // p判断用户选择的登录方式 first-账号密码 second-验证码
      if(this.activeName == 'first'){
        if (
          this.userPhone == "" ||
          this.userPhone == null ||
          this.userPassword == "" ||
          this.userPassword == null
        ){
          this.message.content = "";
          setTimeout(() => {
            this.message.content = "登录失败，用户名或密码不可为空";
            this.message.color = "color:red;";
            this.control_loginloading = false;
          }, 200);
        } else{
          // if(this.isRemember){
          //   localStorage.setItem('isRemember',this.isRemember)
          // }
          // else{
          //   localStorage.removeItem('isRemember')
          // }
          //请求登录接口
          this.reqeustlogin(1)
        }
      }else if(this.activeName == 'second'){
        if( 
          this.userPhone == "" ||
          this.userPhone == null ||
          this.userCode == "" ||
          this.userCode == null
        ){
          this.message.content = "";
          setTimeout(() => {
            this.message.content = "登录失败，手机号或验证码不可为空";
            this.message.color = "color:red;";
            this.control_loginloading = false;
          }, 200);
        }else{
          this.reqeustlogin(2)
        }
      }
      
      
    },
    //请求登录接口
    reqeustlogin(type){
       let requestParams = {
          Version: "",
          MobileNo: this.userPhone,
          type: type,
          PassWord: this.userPassword,
          Zone: "",
          Code: this.userCode,
        };
        // if(!this.isRemember && getCookie('szjz_userpwd')!= null && type == 1){
        //   clearCookie('szjz_userpwd')
        // }
         if(this.certificationAgreement){
            this.$axios("API/UserLogin", requestParams).then((res) => {
              this.message.content = "";
              if (res.status == 500) {
                setTimeout(() => {
                  this.message.content = res.msg;
                  this.message.color = "color:red;";
                  this.control_loginloading = false;
                }, 900);
              } else if (res.status == 202) {
                setTimeout(() => {
                  this.message.content = res.msg;
                  this.message.color = "color:red;";
                  this.control_loginloading = false;
                  this.userPassword = "";
                }, 900);
              } else if (res.status == 200) {
                // localStorage.setItem('isRemember',this.isRemember) //储存记住密码
                if(type == 1 && this.isRemember){  
                  setCookie('szjz_userpwd',this.userPassword,1)
                }
                else if (type == 1 && !this.isRemember){
                  clearCookie('szjz_userpwd')
                }
                setTimeout(() => {
                  this.message.content = res.msg;
                  this.message.color = "color:green;";
                }, 900);
                setTimeout(() => {
                  this.$router.replace("/home");
                  this.control_loginloading = false;
                }, 1300);
                //登录成功将token储存到vuex状态管理中
                // this.$store.commit('token',res.TokenCode)
                this.$store.dispatch("userinfo", res.data);

                //登录成功信息保存到sessionStorage中,该地方嗲用封装过储存对象方式
                setSession("userinfo", res.data);
                localStorage.setItem("userinfo_phone", this.userPhone);
              }
            });
         }else{
            this.$message({
              message:'请先阅读并勾选协议',
              type: 'warning',
            })
            this.control_loginloading = false
         }
    },
    //选中的tab触发 是否展示记住密码框
    selectTab(e){
      if(e.index == 1){
        this.isShow_isRemember = false
      }
      else{
        this.isShow_isRemember = true
      }
    }
  },
  watch: {
    activeName() {
      this.message.content = "";
    },
  },
  directives: {},
};
</script>

<style lang="scss">
  .el-popover{
    z-index: 100000 !important;
  }
  .el-popover {
    padding: 4px 2px;
  }
  @media(min-width: 1201px){
    @import '@/components/content/login/loginFrame.scss';
  }
  @media(max-width: 1200px){
    @import '@/components/content/login/loginFrame_phone.scss';
  }
</style>
